<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>{{ $t('menu.categories') }}</h5>
                <DataTable
                    v-show="filtersInitializationDone"
                    :lazy="true"
                    :value="items"
                    :paginator="true"
                    :rows="perPage"
                    :loading="loading"
                    class="p-mt-3"
                    :totalRecords="totalItems"
                    @page="onPage($event)"
                    @sort="onSort($event)"
                    @filter="onFilter($event)"
                    ref="datatable"
                    responsiveLayout="scroll"
                    :paginatorBottom="true"
                    stateStorage="session"
                    stateKey="dt-state-categories"
                >
                    <template #header>
                        <div class="p-grid">
                            <div class="p-input-icon-left p-col-12 p-md-2">
                                <i class="pi pi-search" />
                                <InputText @input="search" v-model="searchTerm" :placeholder="$t('search.byName')" style="width: 100%" />
                            </div>
                            <div class="p-col-12 p-md-2">
                                <Dropdown style="width: 100%" v-model="sportsModel" :options="sports" optionLabel="displayName" :placeholder="$t('search.bySport')" :filter="!this.isMobile()" />
                            </div>
                            <div class="p-col-12 p-md-2 p-md-offset-6 p-text-right"><Button :label="$t('buttons.add')" class="p-button-raised p-button-success" @click="goto(editPage, { id: 0 })" /></div>
                        </div>
                    </template>
                    <Column
                        ><template #body="slotProps">
                            <span class="indexColumn">{{ (this.crtPage - 1) * this.perPage + slotProps.index + 1 }}</span>
                        </template>
                    </Column>
                    <Column field="id" :header="$t('sportsSettings.id')" :sortable="true"></Column>
                    <Column field="name" :header="$t('sportsSettings.name')" :sortable="true"></Column>
                    <Column field="displayName" :header="$t('sportsSettings.displayName')" :sortable="true"></Column>
                    <Column :header="$t('sportsSettings.sport')">
                        <template #body="slotProps"> {{ sportsDictionary[slotProps.data.sportId] }}</template></Column
                    >
                    <Column field="sortOrder" :header="$t('sportsSettings.sortOrder')" :sortable="true"></Column>
                    <Column :exportable="false" class="p-text-right">
                        <template #body="slotProps">
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" @click="goto(editPage, { id: slotProps.data.id })" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </div>
</template>
<script>
import { SportsSettingsApi } from '../../service/SportSettingsApi';
import { uiSettings } from '../../settings/uiSettings';
import { store } from '../../store/index';
import { debounce } from 'lodash';
export default {
    data() {
        return {
            tableHeight: uiSettings.tableHeight,
            items: [],
            SportsSettingsApi: new SportsSettingsApi(),
            searchTerm: '',
            sort: '',
            crtPage: 0,
            perPage: uiSettings.tableRows,
            loading: true,
            totalItems: 0,
            crtRoute: 'categories',
            sports: [],
            sportsDictionary: {},
            sportsModel: {},
            defaultSportsModel: { displayName: 'All', id: '0' },
            filtersInitializationDone: false,
        };
    },
    computed: {
        storeFilterData() {
            return store.getters['filters/filter'](this.crtRoute);
        },
        editPage() {
            return this.crtRoute + '/edit';
        },
    },
    mounted() {
        this.SportsSettingsApi.getAllSports()
            .then((response) => {
                this.sports = response.data;
                this.sports.unshift(this.defaultSportsModel);
                this.createSportsDictionary();
                if (this.storeFilterData != undefined) {
                    this.searchTerm = this.storeFilterData.data.search;
                    this.sort = this.storeFilterData.data.sort;
                    this.sportsModel = this.sports.find((s) => s.id == this.storeFilterData.data.sportId);
                    this.readItems(this.storeFilterData.data.pageNo);
                } else {
                    this.readItems();
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    this.$store.commit('login/endUserSession');
                    this.goto('login', { session: 'false' });
                }
            });
    },
    watch: {
        sportsModel: function () {
            if (this.filtersInitializationDone) {
                this.composeAndSaveFiltersToStore();
                this.readItems();
            }
        },
    },
    methods: {
        readItems(page = 1) {
            this.crtPage = page;
            this.loading = true;
            let params = {
                per_page: this.perPage,
                page: page,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultSportsModel.id,
            };
            this.SportsSettingsApi.getCategories(params)
                .then((response) => {
                    this.items = response.data.data;
                    this.totalItems = response.data.total;
                    this.loading = false;
                    this.filtersInitializationDone = true;
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        this.$store.commit('login/endUserSession');
                        this.goto('login', { session: 'false' });
                    }
                });
        },
        onPage(event) {
            this.readItems(event.page + 1);
            this.composeAndSaveFiltersToStore();
        },
        onSort(event) {
            let sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.sort = event.sortField + '|' + sortOrder;
            this.readItems();
            this.composeAndSaveFiltersToStore();
        },
        onFilter() {},
        composeAndSaveFiltersToStore() {
            let filters = {
                pageNo: this.crtPage,
                search: this.searchTerm,
                sort: this.sort,
                sportId: this.notNullOrEmptyObject(this.sportsModel) ? this.sportsModel.id : this.defaultSportsModel.id,
            };
            this.saveFiltersToStore(filters, this.crtRoute);
        },
        search: debounce(function () {
            this.composeAndSaveFiltersToStore();
            this.readItems();
        }, 400),
        createSportsDictionary() {
            for (let i = 0; i < this.sports.length; i++) {
                this.sportsDictionary[this.sports[i].id] = this.sports[i].displayName;
            }
        },
    },
};
</script>
